export class SharedCalendarConfig {
  maxDate: Date | null;
  minDate: Date | null;
  minRange: number | null;
  maxRange: number | null;

  constructor(config?: Partial<SharedCalendarConfig>) {
    this.maxDate = config?.maxDate ? new Date(config.maxDate) : null;
    this.minDate = config?.minDate ? new Date(config.minDate) : null;
    this.minRange = config?.minRange && config.minRange > 0 ? config.minRange : null;
    this.maxRange = config?.maxRange && config.maxRange > 0 ? config.maxRange : null;
  }
}
