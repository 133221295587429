@if (formControl) {
<div
  class="shared-form-field shared-select-control size-{{ size }} color-{{ color }}  w-full {{
    labelImage ? 'label-image label-image-' + labelImage : ''
  }}"
  [ngClass]="{ readonly }"
>
  @if (label) {
  <mat-label>{{ label }}</mat-label>
  }
  <mat-form-field appearance="outline">
    <mat-select
      [panelClass]="{ 'shared-select-dropdown': true, 'shared-select-dropdown--custom-option': hasCustomOption }"
      [errorStateMatcher]="_errorStateMatcher"
      [ngModel]="_value"
      [disabled]="disabled || readonly"
      [multiple]="multiple"
      [placeholder]="placeholder"
      [disableRipple]="disableRipple"
      [compareWith]="compareWith"
      (selectionChange)="_changeHandler($event)"
      (focusout)="_onTouched()"
    >
      @if (multiple) {
      <mat-select-trigger>
        @if ({ selected: _selected$ | async }; as data) {
        <div class="w-full pr-2">
          @if (data.selected) {
          <ess-shared-ui-chips-expandable-form-chips
            [items]="data.selected | mapArray : valueLabelMapper"
            [tooltips]="data.selected | mapArray : valueTooltipMapper"
            [errors]="data.selected | mapArray : valueErrorMapper"
            [recordsVisible]="1"
            [expanded]="!!(_touchedChanges$ | async)"
            [disabled]="disabled"
            (remove)="_removeItem(data.selected[$event])"
          />
          }
        </div>
        }
      </mat-select-trigger>
      } @if ((_options$ | async)?.length === 0 && (!clearable || multiple)) {
      <mat-option class="shared-no-options-placeholder" [disabled]="true" [value]="null" />
      } @if (hasCustomOption) {
      <mat-option class="custom-option">
        <ng-content select="[customOption]" />
      </mat-option>
      } @for (option of _options$ | async; track option) {
      <mat-option
        class="size-{{ size }}"
        [value]="option.value"
        [disabled]="_isDisabled(option)"
        [matTooltip]="'You have to select at least ' + minOptionsSelected + ' option(s)'"
        [matTooltipDisabled]="!_isLastSelected(option.value)"
        [matTooltipClass]="'shared-tooltip'"
      >
        @if (!optionTemplate) {
        <div class="w-full" [class.truncate]="size !== 'xs'">{{ option.name }}</div>
        } @else {
        <ng-template
          #customOptionTemplate
          [ngTemplateOutlet]="optionTemplate"
          [ngTemplateOutletContext]="{
            $implicit: option.value,
            name: option.name,
            tooltip: option.tooltip,
            disabled: option.disabled
          }"
        />
        }
      </mat-option>
      } @if (clearable && !multiple) {
      <mat-option class="size-{{ size }}" [class.mdc-list-item--selected]="_value === null" [value]="null">
        <div class="w-full flex justify-between">
          <div class="grow" [class.truncate]="size !== 'xs'">{{ nullOptionName }}</div>
          @if (_value === null) {
          <mat-pseudo-checkbox class="mat-mdc-option-pseudo-checkbox" state="checked" appearance="minimal" />
          }
        </div>
      </mat-option>
      } @for (option of _unavailableOptions$ | async; track option) {
      <mat-option class="!hidden" [value]="option.value" />
      }
    </mat-select>
    @if (hint) {
    <mat-hint [align]="hintAlign">{{ hint }}</mat-hint>
    }
    <mat-error [essErrorMessage]="customErrors || errors" [errorMessageDictionary]="errorMessageDictionary" />
  </mat-form-field>
</div>
}
