import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateAdapter, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { DateRange, MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, map, Observable, tap, timer } from 'rxjs';

import { SharedCalendarConfig } from '@ess/shared/utils/classes';
import { EssDateValidatorsHelper, essFormatDateToISO } from '@ess/shared/utils/helpers';
import { SharedTimespanForm, SharedTimespanValue } from '@ess/shared/utils/models';

import {
  SharedUiComponentsDaterangeCalendarHeaderNextComponent,
  SharedUiComponentsDaterangeCalendarHeaderPreviousComponent,
} from './shared-ui-controls-daterange-calendar-header.component';

import { SharedUiControlsInputControlComponent } from '../shared-ui-controls-input-control/shared-ui-controls-input-control.component';

@Injectable()
class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
}

@UntilDestroy()
@Component({
  selector: 'ess-shared-ui-controls-daterange-calendar',
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SharedUiControlsInputControlComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
  templateUrl: './shared-ui-controls-daterange-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiControlsDaterangeCalendarComponent implements AfterViewInit {
  @Output() selectedChange: EventEmitter<SharedTimespanValue> = new EventEmitter<SharedTimespanValue>();
  @ViewChild('prevCal') prevCalendar!: MatCalendar<Date>;
  @ViewChild('nextCal') nextCalendar!: MatCalendar<Date>;

  @Input() set range(value: SharedTimespanValue | null) {
    this.__localRangeChange = true;
    !value ? this._rangeForm.reset() : this._rangeForm.patchValue({ ...value });
    this.__localRangeChange = false;
  }

  @Input() set config(config: Partial<SharedCalendarConfig>) {
    this._prevCalConfig = new SharedCalendarConfig(config);
    this._prevCalConfig.maxDate?.setDate(0);

    this._nextCalConfig = new SharedCalendarConfig(config);
    if (this._nextCalConfig.minDate) {
      const month = this._nextCalConfig.minDate.getMonth();
      this._nextCalConfig.minDate.setMonth(month + 1);
      this._nextCalConfig.minDate.setDate(1);
    }

    this._calendarsConfig = new SharedCalendarConfig(config);
    this.__setFormValidators();
  }

  readonly error$: Observable<string | null>;

  protected readonly _prevHeaderComponent = SharedUiComponentsDaterangeCalendarHeaderPreviousComponent;
  protected readonly _nextHeaderComponent = SharedUiComponentsDaterangeCalendarHeaderNextComponent;

  protected _calendarsConfig: SharedCalendarConfig = new SharedCalendarConfig();
  protected _prevCalConfig: SharedCalendarConfig = new SharedCalendarConfig();
  protected _nextCalConfig: SharedCalendarConfig = new SharedCalendarConfig();

  protected readonly _rangeForm: FormGroup<SharedTimespanForm> = new FormGroup<SharedTimespanForm>({
    start: new FormControl<string | null>(null),
    end: new FormControl<string | null>(null),
  });

  protected readonly _selected$: Observable<DateRange<Date | null> | Date | null> = this._rangeForm.valueChanges.pipe(
    map((value: Partial<SharedTimespanValue> | null) =>
      value
        ? new DateRange<Date | null>(value.start ? new Date(value.start) : null, value.end ? new Date(value.end) : null)
        : null,
    ),
  );

  protected readonly _rangeErrors$: Observable<ValidationErrors | null> = this._rangeForm.statusChanges.pipe(
    map(() => (this._rangeForm.errors ? { rangeError: ' ' } : null)),
  );

  private __localRangeChange = false;

  constructor(protected readonly __dateAdapter: DateAdapter<Date>) {
    this.error$ = this.__getError$();
  }

  ngAfterViewInit(): void {
    this.__linkCalendars();
    this.__listenRangeChanges();
    this.__listenActiveEndDateChanges();

    // trigger listeners
    this.range = this._rangeForm.getRawValue();
  }

  protected get _startDateControl(): FormControl<string | null> {
    return this._rangeForm.controls.start;
  }

  protected get _endDateControl(): FormControl<string | null> {
    return this._rangeForm.controls.end;
  }

  protected _setSelected(selected: Date | null): void {
    if (selected) {
      const selectedISO: string = essFormatDateToISO(selected);
      const range: SharedTimespanValue = this._rangeForm.getRawValue();
      if (!range.start !== !range.end) {
        const current: string = range.start ?? range.end!;
        if (selectedISO > current) {
          this.__changeRangeSelection(current, selectedISO);
        } else if (selectedISO < current) {
          this.__changeRangeSelection(selectedISO, current);
        }
      } else {
        this.__changeRangeSelection(selectedISO, null);
      }
    }
  }

  private __getError$(): Observable<string | null> {
    return this._rangeForm.statusChanges.pipe(
      map((status) =>
        status === 'VALID' ? null : this._rangeForm.errors ? Object.values(this._rangeForm.errors)[0] : '',
      ),
    );
  }

  private __changeRangeSelection(startDate: string | null, endDate: string | null): void {
    this._endDateControl.patchValue(endDate, { emitEvent: false });
    this._startDateControl.patchValue(startDate);
  }

  private __listenRangeChanges(): void {
    this._rangeForm.valueChanges
      .pipe(
        tap(() => this.__resetCalendarsView()),
        filter(() => !this.__localRangeChange),
        untilDestroyed(this),
      )
      .subscribe(() => this.selectedChange.emit(this._rangeForm.getRawValue()));
  }

  private __linkCalendars(): void {
    const prevChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    const nextChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    this.__listenCalendarChanges(this.prevCalendar, this.nextCalendar, prevChange$, nextChange$, 1);
    this.__listenCalendarChanges(this.nextCalendar, this.prevCalendar, nextChange$, prevChange$, -1);
  }

  private __listenCalendarChanges(
    calendar: MatCalendar<Date>,
    linkedCalendar: MatCalendar<Date>,
    change$: BehaviorSubject<boolean>,
    linkedChange$: BehaviorSubject<boolean>,
    months: number,
  ): void {
    calendar.stateChanges
      .pipe(
        filter(() => {
          const localChange = change$.getValue();
          localChange && change$.next(false);
          return !localChange;
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        linkedChange$.next(true);
        linkedCalendar.activeDate = this.__dateAdapter.addCalendarMonths(calendar.activeDate, months);
      });
  }

  private __listenActiveEndDateChanges(): void {
    this._endDateControl.valueChanges
      .pipe(
        map((endDate: string | null): Date => (endDate ? new Date(endDate) : new Date())),
        untilDestroyed(this),
      )
      .subscribe((endDate: Date) => (this.nextCalendar.activeDate = endDate));
  }

  private __setFormValidators(): void {
    this.__localRangeChange = true;
    const validators: ValidatorFn[] = [Validators.required];
    this._calendarsConfig.minDate && validators.push(EssDateValidatorsHelper.minDate(this._calendarsConfig.minDate));
    this._calendarsConfig.maxDate && validators.push(EssDateValidatorsHelper.maxDate(this._calendarsConfig.maxDate));
    this._startDateControl.setValidators(validators);
    this._endDateControl.setValidators(validators);

    const formValidators: ValidatorFn[] = [EssDateValidatorsHelper.properDateRange()];
    this._calendarsConfig.minRange &&
      formValidators.push(EssDateValidatorsHelper.minRange(this._calendarsConfig.minRange));
    this._calendarsConfig.maxRange &&
      formValidators.push(EssDateValidatorsHelper.maxRange(this._calendarsConfig.maxRange));

    this._rangeForm.setValidators(formValidators);
    this._rangeForm.updateValueAndValidity();
    this.__localRangeChange = false;
  }

  private __resetCalendarsView(): void {
    if (this.prevCalendar.currentView !== 'month') {
      timer(750)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.prevCalendar.currentView = 'month';
          this.nextCalendar.currentView = 'month';
        });
    }
  }
}
