import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ErrorMessageDirective } from '@ess/shared/utils/directives';
import { SharedControlSize, SharedFormOption } from '@ess/shared/utils/models';

import { SharedUiControlsAbstractControlComponent } from '../shared-ui-controls-control/shared-ui-controls-control.component.abstract';

export const BUTTON_TOGGLE_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SharedUiControlsButtonToggleComponent),
  multi: true,
};

@Component({
  selector: 'ess-shared-ui-controls-button-toggle',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorMessageDirective,
  ],
  providers: [BUTTON_TOGGLE_VALUE_ACCESSOR],
  templateUrl: './shared-ui-controls-button-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiControlsButtonToggleComponent<T> extends SharedUiControlsAbstractControlComponent<T> {
  @Input() options: SharedFormOption<T>[] = [];
  @Input() size: SharedControlSize = 's';
  @Input() bottomBarEnabled = false;

  protected _changeHandler(event: Partial<MatButtonToggleChange>): void {
    this._updateValue(event.value);
  }
}
