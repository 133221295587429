import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { ErrorMessageDirective } from '@ess/shared/utils/directives';
import { SharedControlColor, SharedControlSize, SharedErrorMessageDictionary } from '@ess/shared/utils/models';

import { SharedUiControlsAbstractControlComponent } from '../shared-ui-controls-control/shared-ui-controls-control.component.abstract';

const INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SharedUiControlsInputControlComponent),
  multi: true,
};

type HTMLInputType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

@Component({
  selector: 'ess-shared-ui-controls-input-control',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ErrorMessageDirective,
    ReactiveFormsModule,
    SharedUiIconDirective,
  ],
  providers: [INPUT_VALUE_ACCESSOR],
  templateUrl: './shared-ui-controls-input-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiControlsInputControlComponent extends SharedUiControlsAbstractControlComponent<string | number> {
  @Input() placeholder = 'Input';
  @Input() color: SharedControlColor = 'grey';
  @Input() size: SharedControlSize = 's';
  @Input() type: HTMLInputType = 'text';
  @Input() prefixIcon = '';
  @Input() suffixIcon = '';
  @Input() prefixText?: string | null = '';
  @Input() suffixText?: string | null = '';
  @Input() errorMessageDictionary: SharedErrorMessageDictionary | undefined;
  @Input() allowReset = false;

  protected _changeHandler(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this._updateValue(value);
  }

  protected _reset(): void {
    this._updateValue(null);
  }
}
