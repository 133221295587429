@if (formControl) {
<ess-shared-ui-controls-select-control
  #select
  [label]="label"
  [formControl]="formControl"
  [options]="_fakeOptions"
  [clearable]="false"
  [customErrors]="_selectError ?? customErrors"
  [placeholder]="placeholder"
  [size]="size"
  [color]="color"
  [disableRipple]="true"
  [hasCustomOption]="_fakeOptions.length > 0"
  [multiple]="true"
  [labelImage]="labelImage"
  [valueErrorMapper]="valueErrorMapper"
  [valueTooltipMapper]="valueTooltipMapper"
  [valueLabelMapper]="valueLabelMapper"
>
  <div customOption class="shared-tree-select" (click)="$event.stopPropagation()">
    <mat-tree [dataSource]="_dataSource" [treeControl]="_treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <mat-checkbox
          class="checklist-leaf-node ml-2"
          [checked]="_isSelected(node.id)"
          (change)="_leafNodeSelectionToggle(node)"
        >
          <span>{{ node.name }}</span>
        </mat-checkbox>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: _hasChild" matTreeNodePadding>
        <i
          class="text-base-tertiary mr-2 transition-transform"
          essIcon="chevron_right"
          essIconSize="xxl"
          matTreeNodeToggle
          [class.rotate-90]="_treeControl.isExpanded(node)"
        ></i>
        <mat-checkbox
          [checked]="_descendantsAllSelected(node)"
          [indeterminate]="_descendantsPartiallySelected(node)"
          (change)="_nodeSelectionToggle(node)"
        >
          <span>{{ node.name }}</span>
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </div>
</ess-shared-ui-controls-select-control>
}
