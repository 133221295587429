import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { SharedErrorMessageDictionary } from '@ess/shared/utils/models';

@Directive({
  selector: '[essErrorMessage]',
  standalone: true,
})
export class ErrorMessageDirective {
  @Input() errorMessageDictionary: SharedErrorMessageDictionary | undefined;
  @Input() set essErrorMessage(errors: ValidationErrors | null) {
    this.__renderElement(this.__getErrorMessage(errors));
  }

  constructor(private readonly __elementRef: ElementRef<HTMLElement>, private readonly __renderer: Renderer2) {}

  private __renderElement(message: string | null): void {
    this.__renderer.setProperty(this.__elementRef.nativeElement, 'innerHTML', message);
  }

  private __getErrorMessage(errors: ValidationErrors | null): string | null {
    if (!errors || Object.keys(errors)?.length === 0) {
      return null;
    }

    const errorKey: string = Object.keys(errors)?.[0];
    const errorValue: any = errors[errorKey];

    if (this.errorMessageDictionary && this.errorMessageDictionary[errorKey]) {
      return this.errorMessageDictionary[errorKey];
    }

    switch (errorKey) {
      case 'required':
        return `This field is required`;

      case 'maxlength':
        return `Max length: ${errorValue.requiredLength}`;
      case 'minlength':
        return `Min length: ${errorValue.requiredLength}`;

      case 'min':
        return `Min value: ${errorValue.min}`;
      case 'max':
        return `Max value: ${errorValue.max}`;

      case 'invalidAccounts':
        return '';

      default:
        return `${errorValue || 'Wrong value'}`;
    }
  }
}
