import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ErrorMessageDirective } from '@ess/shared/utils/directives';
import { SharedControlColor, SharedControlSize, SharedErrorMessageDictionary } from '@ess/shared/utils/models';

import { SharedUiControlsAbstractControlComponent } from '../shared-ui-controls-control/shared-ui-controls-control.component.abstract';

const TEXTAREA_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SharedUiControlsTextareaControlComponent),
  multi: true,
};
@Component({
  selector: 'ess-shared-ui-controls-textarea-control',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, ErrorMessageDirective],
  providers: [TEXTAREA_VALUE_ACCESSOR],
  templateUrl: './shared-ui-controls-textarea-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiControlsTextareaControlComponent extends SharedUiControlsAbstractControlComponent<string> {
  @Input() placeholder = '';
  @Input() color: SharedControlColor = 'grey';
  @Input() size: SharedControlSize = 'l';
  @Input() errorMessageDictionary: SharedErrorMessageDictionary | undefined;
  @Input() resizeable = false;
  @Input() rows = 10;

  @Output() focusOut: EventEmitter<void> = new EventEmitter<void>();

  protected _changeHandler(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this._updateValue(value);
  }

  protected _focusoutHandler(): void {
    super._onTouched();
    this.focusOut.emit();
  }
}
