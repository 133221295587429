import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, of } from 'rxjs';

import { SharedUiIconDirective } from '@ess/shared/ui/icons';
import { ErrorMessageDirective } from '@ess/shared/utils/directives';
import { SharedFormOption } from '@ess/shared/utils/models';

import { SharedUiControlsInputControlComponent } from '../shared-ui-controls-input-control/shared-ui-controls-input-control.component';

const AUTOCOMPLETE_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SharedUiControlsAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'ess-shared-ui-controls-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    ErrorMessageDirective,
    SharedUiControlsInputControlComponent,
    MatInputModule,
    SharedUiIconDirective,
  ],
  providers: [AUTOCOMPLETE_VALUE_ACCESSOR],
  templateUrl: './shared-ui-controls-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedUiControlsAutocompleteComponent<
  T extends string | number,
> extends SharedUiControlsInputControlComponent {
  @Input() override placeholder = 'Select';
  @Input() override suffixIcon = 'arrow_drop_down';

  @Input() get options(): SharedFormOption<T>[] {
    return this.__options;
  }

  set options(options: SharedFormOption<T>[]) {
    this.__options = options;
    this.__setSelectedOption();
  }

  protected readonly _autocompleteControl = new FormControl<string | null>(null);
  protected _filteredOptions$: Observable<SharedFormOption<T>[]> = of([]);

  private __options: SharedFormOption<T>[] = [];

  constructor(__cdr: ChangeDetectorRef) {
    super(__cdr);
    this.__listenAutocompleteChanges();
  }

  override writeValue(value: T | null): void {
    super.writeValue(value);
    this.__setSelectedOption();
  }

  protected override _updateValue(value: string | number | null): void {
    this._value = value;
    this.__setSelectedOption();
    super._updateValue(value);
  }

  protected _displayFn: (option: SharedFormOption<T>) => string = (option: SharedFormOption<T>) =>
    option?.name || this._autocompleteControl.value || '';

  protected override _changeHandler(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this._autocompleteControl.patchValue(value);
  }

  protected _optionSelected(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.value.value;
    this._value !== newValue && this._updateValue(newValue);
  }

  private __listenAutocompleteChanges(): void {
    this._autocompleteControl.valueChanges
      .pipe(
        map((value) => (value ? this.__filter(value as string) : this.options || [])),
        untilDestroyed(this),
      )
      .subscribe((filteredOptions) => (this._filteredOptions$ = of(filteredOptions)));
  }

  private __filter(value: string): SharedFormOption<T>[] {
    const filterValue = value.toLowerCase();
    return this.options?.filter((option) => option.name.toLowerCase().includes(filterValue)) || [];
  }

  private __setSelectedOption(): void {
    const selected = this.options.find((option) => option.value === this._value);
    selected && this._autocompleteControl.patchValue(selected.name, { emitEvent: false });
    this._filteredOptions$ = of(this.options);
  }
}
