import { SharedAPIConfig } from '@ess/shared/utils/configs';
import { SharedListResponse } from '@ess/shared/utils/models';

export class SharedTableDataPage<T = unknown> {
  pageNumber = 0;
  totalElements = 0;
  size = 0;
  totalPages = 0;

  constructor(list?: SharedListResponse<T> | null, total?: number) {
    this.pageNumber = list ? list.page - 1 : SharedAPIConfig.DEFAULT_PAGE - 1;
    this.size = list ? list.page_size : SharedAPIConfig.DEFAULT_PAGE_SIZE_OPTIONS[0];
    this.totalElements = list ? list.total : total ? total : 0;
    this.totalPages = Math.ceil(this.totalElements / this.size);
  }
}

export class SharedTableData<T> {
  page: SharedTableDataPage;
  items: T[];

  constructor(data?: SharedListResponse<T> | null) {
    this.page = new SharedTableDataPage<T>(data);
    this.items = data?.items ?? [];
  }
}
