@if (formControl) {
<div
  class="shared-form-field shared-input-control shared-textarea-control color-{{ color }} size-{{ size }}"
  [ngClass]="{ readonly }"
>
  @if (label) {
  <mat-label>{{ label }}</mat-label>
  }
  <mat-form-field appearance="outline">
    <textarea
      matInput
      [ngClass]="{ '!resize-none': !resizeable }"
      [ngModel]="_value"
      [placeholder]="placeholder"
      [errorStateMatcher]="_errorStateMatcher"
      [disabled]="disabled"
      [readonly]="readonly"
      [rows]="rows"
      (input)="_changeHandler($event)"
      (focusout)="_focusoutHandler()"
    ></textarea>
    <mat-hint [align]="hintAlign">{{ hint }}</mat-hint>
    <mat-error [essErrorMessage]="errors" [errorMessageDictionary]="errorMessageDictionary"></mat-error>
  </mat-form-field>
</div>
}
