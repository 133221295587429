import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Subject } from 'rxjs';

@UntilDestroy()
@Component({ template: '' })
export abstract class SharedUiControlsAbstractControlComponent<T> implements ControlValueAccessor, OnInit {
  @Input() formControl: FormControl | null = null;
  @Input() label: string | null | undefined = null;
  @Input() readonly = false;
  @Input() hint: string | null = null;
  @Input() hintAlign: 'start' | 'end' = 'start';
  @Input() customErrors: ValidationErrors | null = null;

  protected _value: T | null = null;
  protected _touchedChanges$ = new Subject<boolean>();
  protected _allTouchedChanges$ = new Subject<boolean>();

  private __onChange: ((value: T | null) => void) | undefined;
  private __onTouched: (() => void) | undefined;

  constructor(protected _cdr: ChangeDetectorRef) {}

  get disabled(): boolean {
    return !!this.formControl?.disabled;
  }

  get touched(): boolean {
    return this.formControl?.touched ?? false;
  }

  get errors(): ValidationErrors | null {
    return (this.touched && !this.disabled ? this.formControl?.errors ?? null : null) || this.customErrors;
  }

  writeValue(value: T | null): void {
    this._value = value;
  }

  registerOnChange(fn: (value: T | null) => void): void {
    this.__onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.__onTouched = fn;
  }

  ngOnInit(): void {
    if (!this.formControl) {
      throw new Error(
        `FormControl object ${
          this.label ? 'in ' + this.label + '' : ''
        } is missing. You have to pass it via [formControl] input!`,
      );
    } else {
      this.__extendMarkAsTouched();
      this.__extendMarkAllAsTouched();
      this.__extendUpdateValueAndValidity();
      this.__extendEnable();
      this.__extendDisable();
      this.__extendMarkAsDirty();
      this.__extendMarkAsPristine();
      this.__listenOutsideValueChange();
    }
  }

  protected get _errorStateMatcher(): ErrorStateMatcher {
    const control: FormControl | null = this.formControl;
    const hasCustomErrors = !!this.customErrors && !!Object.keys(this.customErrors).length;

    return {
      isErrorState(): boolean {
        return !!control && ((control?.invalid && control?.touched) || hasCustomErrors);
      },
    };
  }

  protected _onTouched(): void {
    return this.__onTouched && this.__onTouched();
  }

  protected _onChange(value: T | null): void {
    return this.__onChange && this.__onChange(value);
  }

  protected _updateValue(value: T | null): void {
    this._value = value;
    this._onChange(value);
    this._onTouched();
  }

  private __extendMarkAsTouched(): void {
    const oldFunction: (opts?: { onlySelf?: boolean }) => void = this.formControl!.markAsTouched;
    // override markAsTouched
    this.formControl!.markAsTouched = (
      opts?:
        | {
            onlySelf?: boolean | undefined;
          }
        | undefined,
    ) => {
      this._touchedChanges$.next(true);
      oldFunction.call(this.formControl, opts);
      this._cdr.detectChanges();
    };
  }

  private __extendMarkAllAsTouched(): void {
    const oldFunction: (opts?: { onlySelf?: boolean }) => void = this.formControl!.markAllAsTouched;
    // override markAllAsTouched
    this.formControl!.markAllAsTouched = (
      opts?:
        | {
            onlySelf?: boolean | undefined;
          }
        | undefined,
    ) => {
      this._allTouchedChanges$.next(true);
      oldFunction.call(this.formControl, opts);
      this._cdr.detectChanges();
    };
  }

  private __extendUpdateValueAndValidity(): void {
    const oldFunction: (opts?: { onlySelf?: boolean; emitEvent?: boolean }) => void =
      this.formControl!.updateValueAndValidity;

    // override updateValueAndValidity
    this.formControl!.updateValueAndValidity = (
      opts?:
        | {
            onlySelf?: boolean | undefined;
            emitEvent?: boolean | undefined;
          }
        | undefined,
    ) => {
      oldFunction.call(this.formControl, opts);
      this._cdr.detectChanges();
    };
  }

  private __extendEnable(): void {
    const oldFunction: (opts?: { onlySelf?: boolean; emitEvent?: boolean }) => void = this.formControl!.enable;
    this.formControl!.enable = (
      opts?:
        | {
            onlySelf?: boolean | undefined;
            emitEvent?: boolean | undefined;
          }
        | undefined,
    ) => {
      oldFunction.call(this.formControl, opts);
      this._cdr.detectChanges();
    };
  }

  private __extendDisable(): void {
    const oldFunction: (opts?: { onlySelf?: boolean; emitEvent?: boolean }) => void = this.formControl!.disable;
    this.formControl!.disable = (
      opts?:
        | {
            onlySelf?: boolean | undefined;
            emitEvent?: boolean | undefined;
          }
        | undefined,
    ) => {
      oldFunction.call(this.formControl, opts);
      this._cdr.detectChanges();
    };
  }

  private __extendMarkAsDirty(): void {
    const oldFunction: (opts?: { onlySelf?: boolean }) => void = this.formControl!.markAsDirty;
    this.formControl!.markAsDirty = (
      opts?:
        | {
            onlySelf?: boolean | undefined;
          }
        | undefined,
    ) => {
      oldFunction.call(this.formControl, opts);
      this._cdr.detectChanges();
    };
  }

  private __extendMarkAsPristine(): void {
    const oldFunction: (opts?: { onlySelf?: boolean }) => void = this.formControl!.markAsPristine;
    // override markAsTouched
    this.formControl!.markAsPristine = (
      opts?:
        | {
            onlySelf?: boolean | undefined;
          }
        | undefined,
    ) => {
      this._touchedChanges$.next(false);
      this._allTouchedChanges$.next(false);
      oldFunction.call(this.formControl, opts);
      this._cdr.detectChanges();
    };
  }

  private __listenOutsideValueChange(): void {
    if (this.formControl) {
      this.formControl.valueChanges
        .pipe(
          untilDestroyed(this),
          filter((v) => v !== this._value),
        )
        .subscribe((v) => this.writeValue(v));
    }
  }
}
