import { Component, forwardRef, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';

import { SharedCheckboxSize } from '@ess/shared/utils/models';

import { SharedUiControlsAbstractControlComponent } from '../shared-ui-controls-control/shared-ui-controls-control.component.abstract';

const CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SharedUiControlsCheckboxControlComponent),
  multi: true,
};

@Component({
  selector: 'ess-shared-ui-controls-checkbox-control',
  standalone: true,
  imports: [MatCheckboxModule, ReactiveFormsModule, FormsModule],
  providers: [CHECKBOX_VALUE_ACCESSOR],
  templateUrl: './shared-ui-controls-checkbox-control.component.html',
})
export class SharedUiControlsCheckboxControlComponent extends SharedUiControlsAbstractControlComponent<boolean> {
  @Input() size: SharedCheckboxSize = 'm';
  @Input() color: 'primary' | 'green' = 'primary';

  protected _changeHandler(event: MatCheckboxChange): void {
    this._updateValue(event.checked);
  }
}
