import { Params } from '@angular/router';

import { SharedAPIConfig } from '@ess/shared/utils/configs';
import { SharedSortDirection } from '@ess/shared/utils/models';

export class SharedListRequest {
  page: number;
  page_size: number;
  sort_by?: string | number;
  sort_order?: SharedSortDirection;

  constructor(page?: number, page_size?: number, sort?: string | number, sort_dir?: SharedSortDirection) {
    this.page = page ?? SharedAPIConfig.DEFAULT_PAGE;
    this.page_size = page_size ?? SharedAPIConfig.DEFAULT_PAGE_SIZE_OPTIONS[0];
    this.sort_by = sort;
    this.sort_order = sort_dir ?? SharedAPIConfig.DEFAULT_SORT;
  }

  getParams(): Params {
    return {
      page: `${this.page}`,
      page_size: `${this.page_size}`,
      ...(!this.sort_by ? {} : { sort_by: `${this.sort_by}`, sort_order: this.sort_order }),
    };
  }
}
