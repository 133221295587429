import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  TemplateRef,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';

import { SharedUiChipsExpandableFormChipsComponent } from '@ess/shared/ui/chips';
import { ErrorMessageDirective } from '@ess/shared/utils/directives';
import {
  SharedControlColor,
  SharedControlSize,
  SharedErrorMessageDictionary,
  SharedFormOption,
} from '@ess/shared/utils/models';
import { FlattenArrayPipe, MapArrayPipe } from '@ess/shared/utils/pipes';

import { SharedUiControlsAbstractControlComponent } from '../shared-ui-controls-control/shared-ui-controls-control.component.abstract';

const SELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SharedUiControlsSelectControlComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'ess-shared-ui-controls-select-control',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorMessageDirective,
    MatPseudoCheckboxModule,
    MatTooltipModule,
    SharedUiChipsExpandableFormChipsComponent,
    FlattenArrayPipe,
    MapArrayPipe,
  ],
  templateUrl: './shared-ui-controls-select-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SELECT_VALUE_ACCESSOR],
})
export class SharedUiControlsSelectControlComponent<T>
  extends SharedUiControlsAbstractControlComponent<T | T[]>
  implements AfterViewInit
{
  @ContentChild('optionTemplate') optionTemplate: TemplateRef<unknown> | null = null;
  @Input() placeholder = 'Select';
  @Input() multiple = false;
  @Input() clearable = true;
  @Input() nullOptionName = '-- None --';
  @Input() size: SharedControlSize = 's';
  @Input() color: SharedControlColor = 'grey';
  @Input() minOptionsSelected = 0;
  @Input() disableRipple = false;
  @Input() hasCustomOption = false;
  @Input() labelImage = '';
  @Input() errorMessageDictionary: SharedErrorMessageDictionary | undefined;

  @Input() set options(value: SharedFormOption<T>[] | null) {
    this._options$.next(value ?? []);
  }

  protected readonly _options$: BehaviorSubject<SharedFormOption<T>[]> = new BehaviorSubject<SharedFormOption<T>[]>([]);
  protected readonly _unavailableOptions$: BehaviorSubject<SharedFormOption<T>[]> = new BehaviorSubject<
    SharedFormOption<T>[]
  >([]);
  protected readonly _selected$: BehaviorSubject<SharedFormOption<T>[]> = new BehaviorSubject<SharedFormOption<T>[]>(
    [],
  );

  private readonly __value$: BehaviorSubject<T | T[] | null> = new BehaviorSubject<T[] | T | null>(null);

  @Input() compareWith = (v1: T | null, v2: T | null): boolean => v1 === v2;
  @Input() valueErrorMapper = (v: SharedFormOption<T>): boolean => false;
  @Input() valueTooltipMapper = (v: SharedFormOption<T>): string => v.tooltip ?? v.name;
  @Input() valueLabelMapper = (v: SharedFormOption<T>): string => v.name;

  ngAfterViewInit(): void {
    this.__listenSelectionChanges();
  }

  override writeValue(value: T[] | T | null): void {
    super.writeValue(value);
    this.__value$.next(value);
  }

  protected override _updateValue(value: T[] | T | null): void {
    super._updateValue(value);
    this.__value$.next(value);
  }

  protected _isDisabled(option: SharedFormOption<T>): boolean {
    return !!option.disabled || this._isLastSelected(option.value);
  }

  protected _changeHandler(event: Partial<MatSelectChange>): void {
    this._updateValue(event.value);
  }

  protected _isLastSelected(optionValue: T | null): boolean {
    const value = this._value as (T | null)[];
    return !!this.minOptionsSelected && value.length <= this.minOptionsSelected && value.includes(optionValue);
  }

  protected _removeItem(option: SharedFormOption<T>): void {
    if (this._value && Array.isArray(this._value)) {
      let selected = [...this._value];
      selected = selected.filter((o) => o !== option.value);
      this._updateValue(selected);
    }
  }

  private __listenSelectionChanges(): void {
    combineLatest([this.__value$.asObservable(), this._options$.asObservable()])
      .pipe(
        filter(() => this.multiple),
        untilDestroyed(this),
      )
      .subscribe(([currentValue, options]) => {
        if (!Array.isArray(currentValue)) {
          this._unavailableOptions$.next([]);
          this._selected$.next([]);
        } else {
          const selected: SharedFormOption<T>[] = [];
          const value: T[] = [...currentValue];

          selected.push(
            ...options.filter((option) => {
              const index: number = option.value
                ? (value as T[])?.findIndex((value) => this.compareWith(option.value, value))
                : -1;

              const isSelected: boolean = index >= 0;
              isSelected && value.splice(index, 1);
              return isSelected;
            }),
          );
          const unavailable = [
            ...value.map((item: T) => ({
              name: `${item}`,
              value: item,
            })),
          ];

          selected.push(...unavailable);

          this._selected$.next(selected);
          this._unavailableOptions$.next(unavailable);
        }
      });
  }
}
