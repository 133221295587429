<form
  class="flex flex-col justify-start items-stretch"
  [formGroup]="_rangeForm"
  (keydown.tab)="$event.stopPropagation()"
>
  <div
    class="shrink-0 px-2 pt-3 pb-0.5 border-b border-solid border-neutral-variant-300 flex justify-start items-center gap-x-2"
  >
    <ess-shared-ui-controls-input-control
      class="w-40"
      [formControl]="_startDateControl"
      [label]="'Start date'"
      [type]="'date'"
      [customErrors]="_rangeErrors$ | async"
      [suffixIcon]="'calendar_today'"
    ></ess-shared-ui-controls-input-control>
    <span class="w-2 flex-center text-lg">—</span>
    <ess-shared-ui-controls-input-control
      class="w-40"
      [formControl]="_endDateControl"
      [label]="'End date'"
      [type]="'date'"
      [customErrors]="_rangeErrors$ | async"
      [suffixIcon]="'calendar_today'"
    ></ess-shared-ui-controls-input-control>
  </div>

  @if ({ selected: _selected$ | async }; as state) {
  <div class="grid grid-cols-2">
    <div class="relative border-r border-solid border-neutral-variant-300">
      <mat-calendar
        #prevCal
        class="shared-calendar"
        [selected]="state.selected"
        [headerComponent]="_prevHeaderComponent"
        [maxDate]="_prevCalConfig.maxDate"
        [minDate]="_prevCalConfig.minDate"
        (selectedChange)="_setSelected($event)"
      ></mat-calendar>
      @if (nextCal.currentView === 'year') {
      <ng-container *ngTemplateOutlet="overlay; context: { $implicit: 'month', side: 'right' }"></ng-container>
      } @if (nextCal.currentView === 'multi-year') {
      <ng-container *ngTemplateOutlet="overlay; context: { $implicit: 'year', side: 'right' }"></ng-container>
      }
    </div>
    <div class="relative">
      <mat-calendar
        #nextCal
        class="shared-calendar"
        [selected]="state.selected"
        [headerComponent]="_nextHeaderComponent"
        [maxDate]="_nextCalConfig.maxDate"
        [minDate]="_nextCalConfig.minDate"
        (selectedChange)="_setSelected($event)"
      ></mat-calendar>
      @if (prevCal.currentView === 'year') {
      <ng-container *ngTemplateOutlet="overlay; context: { $implicit: 'month', side: 'left' }"></ng-container>
      } @if (prevCal.currentView === 'multi-year') {
      <ng-container *ngTemplateOutlet="overlay; context: { $implicit: 'year', side: 'left' }"></ng-container>
      }
    </div>
  </div>
  }
</form>
<ng-template #overlay let-item let-side="side">
  <div class="absolute w-full h-full top-0 bg-white z-50 flex-center">
    <p class="text-center text-sm text-base-complementary">Choose a {{ item }} on the {{ side }} side</p>
  </div>
</ng-template>
